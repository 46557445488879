<template>
  <vp-base-layout
    page-title="금액입력"
    page-default-back-link="/">
    <ion-list lines="full">
      <ion-list>
        <p>고객에게 입금하실 최종 판매금액을 입력해주세요.</p>
      </ion-list>
      <ion-item>
        <ion-input
          label="고객에게 입금하실 최종 판매금액을 입력해주세요."
          placeholder="ex) 500,000"
          v-model="amount"
          ref="amountEl"
          @ion-input="onInput($event)"
          maxlength="7" />
      </ion-item>
    </ion-list>
    
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="handleEnterAmount">
      금액 입력하기
    </vp-button>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonInput,
  IonList,
  IonItem,
  useIonRouter,
  toastController
} from '@ionic/vue'
import { ref, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const store = useStore()
const ionRouter = useIonRouter()

const fixed = ref(true)
const amount = ref()

const handleCheck = async() => {
  const toast = await toastController.create({
    message: '금액을 정확하게 입력해주세요.',
    buttons: ['확인']
  })
  await toast.present()
}

const amountEl = ref()
const onInput = (ev) => {
  const value = ev.target?.value
  const regex = value.replace(/[^0-9]+/g, '')
  const inputCmp = amountEl.value
  if (inputCmp !== undefined) {
    inputCmp.$el.value = regex
  }
}

//금액 입력하기
const handleEnterAmount = () => {
  if(amount.value === undefined || amount.value === 0 || amount.value > 1000000){
    handleCheck()
    return
  }
  store.dispatch('Receipt/setGrade1Price', amount)
  ionRouter.push({ name: 'collectionFinal'})
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------

</script>